body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: "Caladea";   /*Can be any text*/
    src: local("Caladea"),
      url("./assets/fonts/Caladea-Bold.ttf") format("truetype");
  }




  @font-face {
    font-family: 'Satoshi';
    src: url('./assets/fonts/Satoshi-Light.otf') format('opentype');
    font-weight: 300; /* Specify the font weight */
  }
  @font-face {
    font-family: 'Satoshi';
    src: url('./assets/fonts/Satoshi-Regular.otf') format('opentype');
    font-weight: 400; /* Specify the font weight */
  }
  @font-face {
    font-family: 'Satoshi';
    src: url('./assets/fonts/Satoshi-Medium.otf') format('opentype');
    font-weight: 500; /* Specify the font weight */
  }
  @font-face {
    font-family: 'Satoshi';
    src: url('./assets/fonts/Satoshi-Bold.otf') format('opentype');
    font-weight: 700; /* Specify the font weight */
  }
  @font-face {
    font-family: 'Satoshi';
    src: url('./assets/fonts/Satoshi-Black.otf') format('opentype');
    font-weight: 900; /* Specify the font weight */
  }
